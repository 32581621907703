import axios from "axios";

// Axios docs: https://github.com/axios/axios#request-config //
// https://stackoverflow.com/questions/47407564/change-the-default-base-url-for-axios //
// ~ // `url` is the server URL that will be used for the request
// url: '/user',
//
// ~ // `baseURL` will be prepended to `url` unless `url` is absolute.
// ~ // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
// ~ // to methods of that instance.
// baseURL: 'https://some-domain.com/api/',

// Once on server, replace baseURL block with: process.env.PUBLIC_URL
// console.log( process.env.NODE_ENV );
export default axios.create({
    baseURL: `${
        process.env.NODE_ENV === "development"
            ? // Development API path.
                `//${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}`
            : process.env.NODE_ENV === "production"
            ? // Production API path.
                `//${process.env.REACT_APP_API_IP}`
            : // Default (no env set) API path.
                process.env.PUBLIC_URL
    }`,
    headers: {
        "x-auth-token": localStorage.getItem("token"), // token,
        "Content-type": "application/json",
    },
    // timeout: 1000,
    // `validateStatus` defines whether to resolve or reject the promise for a given
    // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
    // or `undefined`), the promise will be resolved; otherwise, the promise will be
    // rejected.
    /// ~ validateStatus: function (status) {
    /// ~     return status >= 200 && status < 300; // default
    /// ~ },
    // httpsAgent: new https.Agent({
    //     ca: fs.readFileSync(`${path}CA.pem`),
    //     cert: fs.readFileSync(`${path}CERT.pem`),
    //     key: fs.readFileSync(`${path}KEY.pem`),
    //     rejectUnauthorized: false,
    // }),
    // auth: {
    //     username: "username",
    //     password: "password",
    // },
    // baseURL: `http://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}`,
} );

// ~ Valid Axios Instance Methods:
// ^ The available instance methods are listed below. The specified config will be merged with the instance config.
// ^
// ^ axios#request(config)
// ^ axios#get(url[, config])
// ^ axios#delete(url[, config])
// ^ axios#head(url[, config])
// ^ axios#options(url[, config])
// ^ axios#post(url[, data[, config]])
// ^ axios#put(url[, data[, config]])
// ^ axios#patch(url[, data[, config]])
// ^ axios#getUri([config])

// ~ Valid Request method aliases:
// ^ axios.request(config)
// ^ axios.get(url[, config])
// ^ axios.delete(url[, config])
// ^ axios.head(url[, config])
// ^ axios.options(url[, config])
// ^ axios.post(url[, data[, config]])
// ^ axios.put(url[, data[, config]])
// ^ axios.patch(url[, data[, config]])
// ^ axios.postForm(url[, data[, config]])
// ^ axios.putForm(url[, data[, config]])
// ^ axios.patchForm(url[, data[, config]])

// ~ Axios Response Schema:
// * The response for a request contains the following information.
// * {
// *     // `data` is the response that was provided by the server
// *     data: {},
// *
// *     // `status` is the HTTP status code from the server response
// *     status: 200,
// *
// *     // `statusText` is the HTTP status message from the server response
// *     statusText: 'OK',
// *
// *     // `headers` the HTTP headers that the server responded with
// *     // All header names are lowercase and can be accessed using the bracket notation.
// *     // Example: `response.headers['content-type']`
// *     headers: {},
// *
// *     // `config` is the config that was provided to `axios` for the request
// *     config: {},
// *
// *     // `request` is the request that generated this response
// *     // It is the last ClientRequest instance in node.js (in redirects)
// *     // and an XMLHttpRequest instance in the browser
// *     request: {}
// * }

// ~ Axios Request Config
// ~ These are the available config options for making requests. Only the url is required. Requests will default to GET if method is not specified.
// ^ {
// ^     // `url` is the server URL that will be used for the request
// ^     url: '/user',
// ^ 
// ^     // `method` is the request method to be used when making the request
// ^     method: 'get', // default
// ^ 
// ^     // `baseURL` will be prepended to `url` unless `url` is absolute.
// ^     // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
// ^     // to methods of that instance.
// ^     baseURL: 'https://some-domain.com/api',
// ^ 
// ^     // `transformRequest` allows changes to the request data before it is sent to the server
// ^     // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
// ^     // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
// ^     // FormData or Stream
// ^     // You may modify the headers object.
// ^     transformRequest: [function (data, headers) {
// ^         // Do whatever you want to transform the data
// ^ 
// ^         return data;
// ^     }],
// ^ 
// ^     // `transformResponse` allows changes to the response data to be made before
// ^     // it is passed to then/catch
// ^     transformResponse: [function (data) {
// ^         // Do whatever you want to transform the data
// ^ 
// ^         return data;
// ^     }],
// ^ 
// ^     // `headers` are custom headers to be sent
// ^     headers: {'X-Requested-With': 'XMLHttpRequest'},
// ^ 
// ^     // `params` are the URL parameters to be sent with the request
// ^     // Must be a plain object or a URLSearchParams object
// ^     // NOTE: params that are null or undefined are not rendered in the URL.
// ^     params: {
// ^         ID: 12345
// ^     },
// ^ 
// ^     // `paramsSerializer` is an optional function in charge of serializing `params`
// ^     // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
// ^     paramsSerializer: function (params) {
// ^         return Qs.stringify(params, {arrayFormat: 'brackets'})
// ^     },
// ^ 
// ^     // `data` is the data to be sent as the request body
// ^     // Only applicable for request methods 'PUT', 'POST', 'DELETE', and 'PATCH'
// ^     // When no `transformRequest` is set, must be of one of the following types:
// ^     // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
// ^     // - Browser only: FormData, File, Blob
// ^     // - Node only: Stream, Buffer
// ^     data: {
// ^         firstName: 'Fred'
// ^     },
// ^     
// ^     // syntax alternative to send data into the body
// ^     // method post
// ^     // only the value is sent, not the key
// ^     data: 'Country=Brasil&City=Belo Horizonte',
// ^ 
// ^     // `timeout` specifies the number of milliseconds before the request times out.
// ^     // If the request takes longer than `timeout`, the request will be aborted.
// ^     timeout: 1000, // default is `0` (no timeout)
// ^ 
// ^     // `withCredentials` indicates whether or not cross-site Access-Control requests
// ^     // should be made using credentials
// ^     withCredentials: false, // default
// ^ 
// ^     // `adapter` allows custom handling of requests which makes testing easier.
// ^     // Return a promise and supply a valid response (see lib/adapters/README.md).
// ^     adapter: function (config) {
// ^         /* ... */
// ^     },
// ^ 
// ^     // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
// ^     // This will set an `Authorization` header, overwriting any existing
// ^     // `Authorization` custom headers you have set using `headers`.
// ^     // Please note that only HTTP Basic auth is configurable through this parameter.
// ^     // For Bearer tokens and such, use `Authorization` custom headers instead.
// ^     auth: {
// ^         username: 'janedoe',
// ^         password: 's00pers3cret'
// ^     },
// ^ 
// ^     // `responseType` indicates the type of data that the server will respond with
// ^     // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
// ^     //     browser only: 'blob'
// ^     responseType: 'json', // default
// ^ 
// ^     // `responseEncoding` indicates encoding to use for decoding responses (Node.js only)
// ^     // Note: Ignored for `responseType` of 'stream' or client-side requests
// ^     responseEncoding: 'utf8', // default
// ^ 
// ^     // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
// ^     xsrfCookieName: 'XSRF-TOKEN', // default
// ^ 
// ^     // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
// ^     xsrfHeaderName: 'X-XSRF-TOKEN', // default
// ^ 
// ^     // `onUploadProgress` allows handling of progress events for uploads
// ^     // browser only
// ^     onUploadProgress: function (progressEvent) {
// ^         // Do whatever you want with the native progress event
// ^     },
// ^ 
// ^     // `onDownloadProgress` allows handling of progress events for downloads
// ^     // browser only
// ^     onDownloadProgress: function (progressEvent) {
// ^         // Do whatever you want with the native progress event
// ^     },
// ^ 
// ^     // `maxContentLength` defines the max size of the http response content in bytes allowed in node.js
// ^     maxContentLength: 2000,
// ^ 
// ^     // `maxBodyLength` (Node only option) defines the max size of the http request content in bytes allowed
// ^     maxBodyLength: 2000,
// ^ 
// ^     // `validateStatus` defines whether to resolve or reject the promise for a given
// ^     // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
// ^     // or `undefined`), the promise will be resolved; otherwise, the promise will be
// ^     // rejected.
// ^     validateStatus: function (status) {
// ^         return status >= 200 && status < 300; // default
// ^     },
// ^ 
// ^     // `maxRedirects` defines the maximum number of redirects to follow in node.js.
// ^     // If set to 0, no redirects will be followed.
// ^     maxRedirects: 5, // default
// ^ 
// ^     // `socketPath` defines a UNIX Socket to be used in node.js.
// ^     // e.g. '/var/run/docker.sock' to send requests to the docker daemon.
// ^     // Only either `socketPath` or `proxy` can be specified.
// ^     // If both are specified, `socketPath` is used.
// ^     socketPath: null, // default
// ^ 
// ^     // `httpAgent` and `httpsAgent` define a custom agent to be used when performing http
// ^     // and https requests, respectively, in node.js. This allows options to be added like
// ^     // `keepAlive` that are not enabled by default.
// ^     httpAgent: new http.Agent({ keepAlive: true }),
// ^     httpsAgent: new https.Agent({ keepAlive: true }),
// ^ 
// ^     // `proxy` defines the hostname, port, and protocol of the proxy server.
// ^     // You can also define your proxy using the conventional `http_proxy` and
// ^     // `https_proxy` environment variables. If you are using environment variables
// ^     // for your proxy configuration, you can also define a `no_proxy` environment
// ^     // variable as a comma-separated list of domains that should not be proxied.
// ^     // Use `false` to disable proxies, ignoring environment variables.
// ^     // `auth` indicates that HTTP Basic auth should be used to connect to the proxy, and
// ^     // supplies credentials.
// ^     // This will set an `Proxy-Authorization` header, overwriting any existing
// ^     // `Proxy-Authorization` custom headers you have set using `headers`.
// ^     // If the proxy server uses HTTPS, then you must set the protocol to `https`. 
// ^     proxy: {
// ^         protocol: 'https',
// ^         host: '127.0.0.1',
// ^         port: 9000,
// ^         auth: {
// ^             username: 'mikeymike',
// ^             password: 'rapunz3l'
// ^         }
// ^     },
// ^ 
// ^     // `cancelToken` specifies a cancel token that can be used to cancel the request
// ^     // (see Cancellation section below for details)
// ^     cancelToken: new CancelToken(function (cancel) {
// ^     }),
// ^ 
// ^     // `decompress` indicates whether or not the response body should be decompressed 
// ^     // automatically. If set to `true` will also remove the 'content-encoding' header 
// ^     // from the responses objects of all decompressed responses
// ^     // - Node only (XHR cannot turn off decompression)
// ^     decompress: true // default
// ^ 
// ^ }


// const axiosDefaultConfig = {
//     proxy: {
//         host: "http://localhost:3000",
//         hostname: "127.0.0.1",
//         port: 5000,
//         protocol: "http",
//     },
// };
// const axiosFixed = require("axios-https-proxy-fix").create(axiosDefaultConfig);
// console.log(
//     "AXIOS DEFAULTS :: ",
//     axios.defaults,
//     ", axios overall = ",
//     axios,
//     "API DEFAULTS :: ",
//     API.defaults,
//     ", API overall = ",
//     API,
//     ", env variables = ",
//     process.env,
//     process.env.REACT_APP_API_PORT,
//     process.env.REACT_APP_API_IP,
//     process.env.REACT_APP_API_PATH,
// );
// axios.defaults.baseURL = `http://localhost:4000`;
// axios.defaults.baseURL = `http://127.0.0.1:4000`;
// Axios for some reason calls this by default:
// http://147.182.184.250:3000/seabAu/seangb-portfolio/api/portfolio/get-portfolio-data
// axios.defaults.baseURL = `http://147.182.184.250:4000`;

// axios.defaults.baseURL = "http://localhost:4000";
//
// const getPortfolioData = async () => {
//     try {
//         // Set reloadData flag false.
//         dispatch(SetLoading(true));
//         dispatch(ReloadData(false));
//         console.log("getPortfolioData :: Before axios fetch.");
//         // For some reason this function dies right here.
//         const response = await axios
//             .get("/api/portfolio/get-portfolio-data")
//             .then((res) => {
//                 console.log("getPortfolioData :: res = ", res);
//                 dispatch(SetPortfolioData(res.data));
//             })
//             .catch((err) => {
//                 console.error(err);
//             });
//
//         dispatch(SetLoading(false));
//     } catch ( error )
//     {
//         console.error( error );
//         dispatch(SetLoading(false));
//     }
// };
